import { CheckboxVisibility, Icon } from '@fluentui/react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { OnixTooltip } from '@onix/common/src/components/OnixTooltip/OnixTooltip';
import { ForwardedRef, forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShowUpgradeOWMessage } from '../../../../common/hooks/useShowUpgradeOWMessage';
import { useTracking } from '../../../../common/hooks/useTracking';
import PrimaryButton from '../../../../common/ui/Button/PrimaryButton';
import { NoData } from '../../../../common/ui/NoData/NoData';
import { Skeleton } from '../../../../common/ui/Skeleton/Skeleton';
import { onCreateColumn } from '../../../../common/ui/TableList/Config';
import { Modules } from '../../../../constants/modules.constants';
import { TrackingEvent } from '../../../../constants/tracking-event.constant';
import { UnitOfMeasureConstants } from '../../../../constants/unit-of-measure.constants';
import { JobClass } from '../../../../enums/job.enum';
import { StatusValues } from '../../../../enums/status-value.enum';
import { UnitOfMeasure } from '../../../../enums/unit-of-measure.enum';
import { JobHelper } from '../../../../helpers/job-helper';
import { MaintenancePlan } from '../../../../models/job-intervals/job-interval.model';
import { equipmentService } from '../../../../services/equipment.service';
import { formatDate } from '../../../../utils/dateTime';
import { nameof } from '../../../../utils/object';
import { EquipmentDetailContext } from '../EquipmentDetail';
import './index.scss';

export interface JobIntervalRef {
  refresh(): Promise<void>;
}

const DefaultUnitOfMeasureCaption = UnitOfMeasureConstants.Captions[UnitOfMeasure.Month];

interface IJobIntervalProps {
  equipmentId: number;
  isAllowLoadData?: boolean;
  setJobInterValCount?: (value: number) => void;
}
const StandarColumnWidth = 150;

export const JobInterval = forwardRef((props: IJobIntervalProps, ref: ForwardedRef<JobIntervalRef>) => {
  const [translate] = useTranslation();
  const { isLoading: equipmentIsLoading, jobIntervals, setJobIntervals, refresh: refreshEquipment } = useContext(EquipmentDetailContext);
  const { equipmentId, isAllowLoadData, setJobInterValCount } = props;
  const [loadedEquipmentId, setLoadedEquipmentId] = useState<number | null>(null);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const { showUpgradeOWMessage } = useShowUpgradeOWMessage();
  const { trackEvent } = useTracking({ module: Modules.Equipment });

  useImperativeHandle(
    ref,
    () =>
      ({
        refresh: fetchData,
      } as JobIntervalRef)
  );

  const getIconDoneStatusClass = (status: number) => {
    if (status !== undefined && status !== null) {
      status = status == StatusValues.U ? StatusValues.None : status;
      if (StatusValues.I == status || (StatusValues.None <= status && status <= StatusValues.U)) {
        switch (status) {
          case StatusValues.NC:
            return 'icon oi-status-square-nc status-icon status-1';
          case StatusValues.RC:
            return 'icon oi-status-square-rc status-icon status-2';
          case StatusValues.MO:
            return 'icon oi-status-square-mo status-icon status-3';
          case StatusValues.C:
            return 'icon i-status-square-c status-icon status-4';
          case StatusValues.OK:
            return 'icon oi-status-square-ok status-icon status-5';
          case StatusValues.U:
            return 'icon oi-status-square-u status-icon status-6';
          case StatusValues.NA:
            return 'icon oi-status-square-na status-icon status-8';
          case StatusValues.I:
            return 'icon oi-status-square-check status-icon status-9';
          case StatusValues.None:
            return 'icon oi-status-square-check status-icon status-0';
        }
      }
    }
    return '';
  };

  const getIconDueStatusClass = (jobClassId: number, dueStatus: number) => {
    switch (jobClassId) {
      case JobClass.Operator:
        return `icon oi-jc-maint-operator ${JobHelper.getDueStatusIconClassName(dueStatus)}`;

      case JobClass.Preventive:
        return `icon oi-jc-maint-preventive  ${JobHelper.getDueStatusIconClassName(dueStatus)}`;

      case JobClass.Mandatory:
        return `icon icon oi-jc-legal  ${JobHelper.getDueStatusIconClassName(dueStatus)}`;
    }
    return '';
  };

  const columnConfigs: IColumn[] = [
    {
      key: 'jobclass',
      name: translate('Settings.JobClass'),
      minWidth: StandarColumnWidth,
      fieldName: nameof<MaintenancePlan>('jobClassId'),
      onRender: (item: MaintenancePlan, index) => {
        return (
          <>
            <OnixTooltip
              content={
                <>
                  <div>{translate(`StaticValues.JobClass_${item.jobClassId}`)}</div>
                </>
              }
            >
              <div>{translate(`StaticValues.JobClass_${item.jobClassId}`)}</div>
            </OnixTooltip>
          </>
        );
      },
      data: {
        isHasToolTip: false,
      },
    },
    {
      key: 'interval',
      name: translate('Home.Interval'),
      minWidth: StandarColumnWidth,
      fieldName: nameof<MaintenancePlan>('interval'),
      onRender: (item: MaintenancePlan, index) => {
        return (
          <>
            <OnixTooltip
              content={
                <>
                  <div>
                    {item.interval}{' '}
                    {translate((UnitOfMeasureConstants.Captions as any)[item.unitOfMeasureId] ?? DefaultUnitOfMeasureCaption)}
                  </div>
                </>
              }
            >
              <div>
                {item.interval} {translate((UnitOfMeasureConstants.Captions as any)[item.unitOfMeasureId] ?? DefaultUnitOfMeasureCaption)}
              </div>
            </OnixTooltip>
          </>
        );
      },
      data: {
        isHasToolTip: false,
      },
    },
    {
      key: 'doneDate',
      name: translate('Equipment.DoneDate'),
      minWidth: StandarColumnWidth,
      fieldName: nameof<MaintenancePlan>('doneDate'),
      onRender: (item: MaintenancePlan, index) => {
        return (
          <>
            <OnixTooltip
              content={
                <>
                  <div className="job-interval">
                    <div className="d-flex">
                      <div>{formatDate(item.doneDate)}</div>
                      <Icon style={{ lineHeight: '18px' }} className={getIconDoneStatusClass(item.doneStatus)} />
                    </div>
                  </div>
                </>
              }
            >
              <div className="d-flex">
                <div>{formatDate(item.doneDate)}</div>
                <Icon style={{ lineHeight: '18px' }} className={getIconDoneStatusClass(item.doneStatus)} />
              </div>
            </OnixTooltip>
          </>
        );
      },
      data: {
        isHasToolTip: false,
      },
    },
    {
      key: 'dueDate',
      name: translate('Equipment.DueDate'),
      minWidth: StandarColumnWidth,
      fieldName: nameof<MaintenancePlan>('dueDate'),
      onRender: (item: MaintenancePlan, index) => {
        return (
          <>
            <OnixTooltip
              content={
                <>
                  <div className="job-interval">
                    <div className="d-flex">
                      <div>{formatDate(item.dueDate)}</div>
                      <Icon style={{ lineHeight: '18px' }} className={getIconDueStatusClass(item.jobClassId, item.dueDateStatus)} />
                    </div>
                  </div>
                </>
              }
            >
              <div className="d-flex">
                <div>{formatDate(item.dueDate)}</div>
                <Icon style={{ lineHeight: '18px' }} className={getIconDueStatusClass(item.jobClassId, item.dueDateStatus)} />
              </div>
            </OnixTooltip>
          </>
        );
      },
      data: {
        isHasToolTip: false,
      },
    },
  ];

  const fetchData = async () => {
    if (equipmentId) {
      const res = await equipmentService.getJobInterValList(equipmentId, false);
      setLoadedEquipmentId(equipmentId);
      setJobIntervals(res);
      if (setJobInterValCount) {
        setJobInterValCount(res.length);
      }
    }
  };

  useEffect(() => {
    if (equipmentId && isAllowLoadData && equipmentId !== loadedEquipmentId) {
      fetchData();
    }
  }, [equipmentId, isAllowLoadData, loadedEquipmentId]);

  useEffect(() => {
    setColumns(getColumns());
  }, []);

  const getColumns = (): IColumn[] => {
    const columData: IColumn[] = [];
    columnConfigs.forEach((col) => {
      columData.push(onCreateColumn(col));
    });
    return columData;
  };

  return (
    <div className="job-interval-section section">
      {equipmentIsLoading ? (
        <Skeleton numberElement={jobIntervals ? jobIntervals.length + 3 : 3} className="section-row" />
      ) : (
        <>
          <PrimaryButton
            iconProps={{ iconName: 'Add' }}
            text={translate('Equipment.Addinterval')}
            style={{ marginRight: 'auto' }}
            onClick={() => {
              showUpgradeOWMessage();
              trackEvent(TrackingEvent.Equipment.EquipmentDetail.Detail.JobIntervals.Add);
            }}
          />
          {jobIntervals && jobIntervals?.length > 0 ? (
            <DetailsList
              checkboxVisibility={CheckboxVisibility.hidden}
              items={jobIntervals}
              columns={columns}
              compact={true}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              setKey="none"
              className="table-list"
              selectionMode={SelectionMode.none}
            />
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  );
});

JobInterval.displayName = 'JobInterval'; // For devtool display name.
