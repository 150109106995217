import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import Loading from '../../common/ui/Loading/Loading';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { CompanyExpressContextInfo } from '../../models/company-express-context.model';
import { CompanyExpressSetup } from '../../models/company-express-setup.model';
import { Contact } from '../../models/user-account-information.model';
import { appSelector } from '../../states/app/appSlice';
import { setPageTitle } from '../../utils/browser.utils';
import { setupAppColor } from '../../utils/css.utils';
import { getAuthenticatedProfile } from '../../utils/localStorage.utils';

export const CompanyExpressContext = createContext({
  info: {} as CompanyExpressContextInfo,
  setInfo: (info: CompanyExpressContextInfo) => {},
});

const MainLayout = () => {
  const { isLoading } = useSelector(appSelector);
  const [expressInfo, setExpressInfo] = useState<CompanyExpressContextInfo>({
    companyExpressSetup: {} as CompanyExpressSetup,
    expressId: 0,
    expressName: '',
    contact: {} as Contact,
  } as CompanyExpressContextInfo);

  const authenProfile = getAuthenticatedProfile();

  useEffect(() => {
    if (authenProfile) {
      setExpressInfo({ ...authenProfile });
      setupAppColor({
        primary: authenProfile?.companyExpressSetup?.primaryColor,
        primaryText: authenProfile?.companyExpressSetup?.primaryTextColor,
      });
      setPageTitle(authenProfile.companyExpressSetup.companyName);
    }
  }, []);

  return (
    <CompanyExpressContext.Provider value={{ info: expressInfo, setInfo: setExpressInfo }}>
      <Header />
      <div className="w-screen h-[calc(100vh-70px)] flex overflow-hidden">
        <Outlet></Outlet>
      </div>
      <Footer />
      {isLoading && <Loading />}
    </CompanyExpressContext.Provider>
  );
};

export default MainLayout;
