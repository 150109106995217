import { WebStorageStateStore } from 'oidc-client';
import { EnvironmentSettings } from '../models/app-settings.model';

export const environment: EnvironmentSettings = {
  appName: 'onix-express',
  clientApplicationId: 20,
  environment: 'beta',
  apiUrl: 'https://onix-express-api-beta.azurewebsites.net',
  myAccountUrl: 'https://myaccount-beta.onixwork.com',
  identityApiUrl: 'https://login-beta.onixwork.com',
  transferUrl: 'https://onix-transfer-beta.azurewebsites.net',
  enableBanner: false,
  applicationInsightKey: 'e85b9634-431e-4f6c-8415-ca3bbca67c0b',
  applicationInsightBufferKey: 'applicationInsightBuffer',
  oidcSetting: {
    authority: 'https://login-beta.onixwork.com',
    client_id: 'D1T3s9CdzvN22pb75rk6fUZDZ1uyYvV7be',
    redirect_uri: 'https://express-beta.onixwork.com/auth-callback',
    post_logout_redirect_uri: 'https://express-beta.onixwork.com/logout',
    response_type: 'code',
    scope: 'openid email onixexpress offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    monitorSession: true,
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
