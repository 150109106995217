import { WebStorageStateStore } from 'oidc-client';
import { EnvironmentSettings } from '../models/app-settings.model';

export const environment: EnvironmentSettings = {
  appName: 'onix-express',
  clientApplicationId: 20,
  environment: 'test',
  apiUrl: 'https://onix-express-api-test.azurewebsites.net',
  myAccountUrl: 'https://myaccount-test.onixwork.com',
  identityApiUrl: 'https://login-test.onixwork.com',
  transferUrl: 'https://onix-transfer-test.azurewebsites.net',
  enableBanner: true,
  applicationInsightKey: '1fba0b21-bb90-44aa-9e70-fe85359c891f',
  applicationInsightBufferKey: 'applicationInsightBuffer',
  oidcSetting: {
    authority: 'https://login-test.onixwork.com',
    client_id: 'D1T3s9CdzvN22pb75rk6fUZDZ1uyYvV7te',
    redirect_uri: 'https://express-test.onixwork.com/auth-callback',
    post_logout_redirect_uri: 'https://express-test.onixwork.com/logout',
    response_type: 'code',
    scope: 'openid email onixexpress offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    monitorSession: true,
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
