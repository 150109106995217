import { ListTiles } from '@onix/common';
import { NotHitMessage } from '@onix/common/src/assets/layouts';
import { IOnixListTilesImperative } from '@onix/common/src/components/ListTiles/ListTiles';
import { IOnixTablePagination, IOnixTableRequest } from '@onix/common/src/components/OnixTable/IOnixTable';
import { OnixTooltip } from '@onix/common/src/components/OnixTooltip/OnixTooltip';
import { SortedColumn } from '@onix/common/src/models/sorted-column.model';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon } from '../../../../common/ui/Icon/Icon';
import { ApiConstants } from '../../../../constants/api.constants';
import { ImagePaths } from '../../../../constants/image-path.constants';
import { CaptionForm } from '../../../../enums/language.enum';
import { EquipmentHelper } from '../../../../helpers/equipment-helper';
import { IPicture } from '../../../../models/equipmentDetail/equipment-image.model';
import { equipmentSelector } from '../../../../states/equipment/equipmentSlice';
import { formatDate } from '../../../../utils/dateTime';
import { PreviewPicturePanel } from '../PicturePreviewPanel/PicturePreviewPanel';
import './index.scss';

export interface IPictureTilesViewImperative {
  clearAll: () => void;
}

export interface PictureTilesViewProps {
  equipmentId: number;
  selectedItemIds?: string[];
  onSelectionChanged?: (item: IPicture[]) => void;
  getItems: (request: IOnixTableRequest, sortedColumn: SortedColumn) => Promise<any>;
}

export const PictureTilesView = forwardRef((props: PictureTilesViewProps, ref: React.Ref<IPictureTilesViewImperative>) => {
  const blankLogo = ImagePaths.NoImageAvailableBig;
  const [translate] = useTranslation();
  const [isOpenPreviewPicture, setIsOpenPreviewPicture] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | undefined>();
  const tableRef = useRef<IOnixListTilesImperative | null>(null);
  const [pagedItems, setPagedItems] = useState<IOnixTablePagination>();
  const isFirstTimeOpening = useRef(true);

  useImperativeHandle(ref, () => {
    const result: IPictureTilesViewImperative = {
      clearAll,
    };
    return result;
  });

  // selector
  const { showPictureManagement } = useSelector(equipmentSelector);

  const { getItems, onSelectionChanged, selectedItemIds } = props;

  const clearAll = () => {
    tableRef.current?.selectItems([selectedItemIds]);
  };

  useEffect(() => {
    if (
      pagedItems &&
      pagedItems.items?.length > 0 &&
      !isOpenPreviewPicture &&
      isFirstTimeOpening.current &&
      showPictureManagement?.imageContentId
    ) {
      setSelectedKey(EquipmentHelper.generatePictureKey(props.equipmentId, showPictureManagement.imageContentId));
      setIsOpenPreviewPicture(true);
      isFirstTimeOpening.current = false;
    }
  }, [pagedItems]);

  const getPictures = async (request: IOnixTableRequest) => {
    const result = (await getItems(request, { columnName: 'createdDate', isDescending: true } as SortedColumn)) as IOnixTablePagination;
    if (result) {
      const oldItems = tableRef.current?.getAllItems() as any[];
      const updatedItems = oldItems.concat(result.items);
      const updatedPagedItems = { ...result, items: updatedItems } as IOnixTablePagination;
      setPagedItems(updatedPagedItems);
    }
    return result;
  };

  const emptyData = () => {
    return (
      <>
        <NotHitMessage>
          <div>{translate(`${CaptionForm.CommonCaption}.EmptyMessageSubList`)}</div>
        </NotHitMessage>
      </>
    );
  };

  const onRenderItem = useCallback(
    (index: number | undefined, item: IPicture) => {
      if (!item) {
        return <></>;
      }

      return (
        <>
          <div className="ReactGridGallery_tile-viewport">
            <img
              alt="alt"
              src={ApiConstants.equipment.getThumbnailImageFileByImageContentId(item.imageContentId)}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = blankLogo;
              }}
              loading="lazy"
            />
          </div>
          <div className="ReactGridGallery_tile-bottom-bar-content">
            <div className="picture-info">
              <OnixTooltip content={item.description}>
                <div className="picture-description text-three-dots">{item.description}</div>
              </OnixTooltip>
              <div className="text created-date">{formatDate(item.createdDate)}</div>
              {item.isDefaultImage ? (
                <div className="icon">
                  <Icon className="oi-image-check" />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      );
    },
    [blankLogo]
  );

  const onClickPicture = (index: number | undefined, item: any) => {
    setSelectedKey(item.key);
    setIsOpenPreviewPicture(true);
  };

  return (
    <div className="picture-tiles-view-container">
      <ListTiles
        ref={tableRef}
        onGetItems={getPictures}
        onRenderEmpty={emptyData}
        onRenderItem={onRenderItem}
        onClickItem={onClickPicture}
        predicateToGetKey={(picture: IPicture) => picture.key}
        onSelectionChange={onSelectionChanged}
      />

      {isOpenPreviewPicture && pagedItems && selectedKey && (
        <PreviewPicturePanel
          isOpen={isOpenPreviewPicture}
          pagedItems={pagedItems}
          selectedKey={selectedKey}
          equipmentId={props.equipmentId}
          onDismiss={() => {
            setIsOpenPreviewPicture(false);
            isFirstTimeOpening.current = false;
          }}
        />
      )}
    </div>
  );
});
