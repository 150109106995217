import { PanelType } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldFilter, FilterControlRef, ISearchFieldProps } from '../../../../models/filter-controls/search-field.model';
import '../../../../utils/string';
import { useDebouncedEffect } from '../../../hooks/useDebouncedEffect';
import { IMultipleSelectionFieldOption } from '../../MultipleSelectionField/IMultipleSelectionField';
import MultipleSelectionField from '../../MultipleSelectionField/MultipleSelectionField';

const MultipleWithSearchFilterField = (props: ISearchFieldProps) => {
  const [translate] = useTranslation();
  const [searchTextValue, setSearchTextValue] = useState<string | undefined>(undefined);
  const [selectedOptions, setSelectedOptions] = useState<IMultipleSelectionFieldOption[]>([]);
  const [panelOptions, setPanelOptions] = useState<IMultipleSelectionFieldOption[]>([]);

  useEffect(() => {
    const controlRef = new FilterControlRef();
    controlRef.onClearData = onClearData;
    controlRef.getFilter = getFilter;
    props.field.controlRef = controlRef;
    if (props.field.value !== undefined) {
      const opts = props.field.options;
      setSelectedOptions(opts);
    }
  }, []);

  useDebouncedEffect(
    () => {
      if (searchTextValue !== undefined) {
        onSearch(searchTextValue || '');
      }
    },
    [searchTextValue],
    500
  );

  const onClearData = () => {
    setSelectedOptions([]);
    props.field.value = '';
    props.field.options = [];

    if (typeof props.onDataChanged === 'function') {
      setTimeout(props.onDataChanged, 100);
    }
  };

  const onSearch = async (text: string) => {
    if (typeof props.onSearch === 'function') {
      let options: IMultipleSelectionFieldOption[] = [];
      const data = await props.onSearch(text);
      if (data) {
        options = data.map((item: any) => {
          return {
            id: item.key,
            description: item.text,
            data: item,
            isGroup: item.isGroup,
            groupKey: item.groupKey,
            isHiddenCheckbox: item.isHiddenCheckbox,
          };
        });
      }
      setPanelOptions(options);
    }
  };

  const getFilter = (): FieldFilter | undefined => {
    const value = (props.field.value ?? '').trim();
    if (value !== '') {
      const filter = new FieldFilter();
      filter.fieldName = props.field.key;
      filter.type = props.field.type;
      filter.fieldValue = value;
      filter.options = props.field.options;
      return filter;
    }
    return undefined;
  };

  const onOptionChanges = (options: IMultipleSelectionFieldOption[]) => {
    setSelectedOptions(options);
    if (options.length === 0) {
      props.field.value = undefined;
      props.field.options = [];
    } else {
      props.field.value = options.map((m) => m.id).join(',');
      props.field.options = options;
    }
    if (typeof props.onDataChanged === 'function') {
      setTimeout(props.onDataChanged, 100);
    }
  };

  const onRenderLabelCheckbox = useCallback((item: any, checkboxProps: any, defaultRender: any) => {
    if (props.onRenderLabelCheckbox) return props.onRenderLabelCheckbox(item, checkboxProps, defaultRender);

    if (defaultRender) {
      return defaultRender(checkboxProps);
    }
    return <></>;
  }, []);

  const tryToCloseDetailPanel = () => {
    if (props.detailPanelRef && props.detailPanelRef?.current && props.detailPanelRef?.current?.onDismissPanel) {
      props.detailPanelRef?.current?.onDismissPanel();
    }
  };

  return (
    <MultipleSelectionField
      selectedOptions={selectedOptions}
      options={panelOptions}
      isAutoFocusSearchBox
      isDisplayClearButton
      textFieldProps={{
        label: translate(props.field.label),
        placeholder: translate(props.field.placeholder ?? ''),
        disabled: props.field.disabled,
      }}
      panelProps={{
        type: PanelType.custom,
        customWidth: '400px',
        className: 'multiple-with-search-field-panel',
        title: translate(props.field.label ?? ''),
      }}
      onAfterClickTextField={() => {
        tryToCloseDetailPanel();
        onSearch('');
      }}
      onOptionChanges={onOptionChanges}
      onSearchTextChanges={setSearchTextValue}
      onAfterClearIconClick={onClearData}
      onRenderLabelCheckbox={onRenderLabelCheckbox}
    />
  );
};

export default MultipleWithSearchFilterField;
