import { ApiConstants } from '../constants/api.constants';
import { CompanyExpressPreviewContextType } from '../context/CompanyExpressPreviewContext';
import { ColleagueItem } from '../models/colleague.model';
import { CompanyExpressItem } from '../models/company-express-item.model';
import { Company } from '../models/company.model';
import { InviteExpressUserResult, NewUserExpressItem } from '../models/invite-express-user.model';
import { SortedColumn } from '../models/sorted-column.model';
import { getAsync, postAsync } from './base.service';

const getListCompanyExpress = (companyId: number, email: string, searchText?: string): Promise<CompanyExpressItem[]> => {
  return getAsync(ApiConstants.company.getListCompanyExpress(companyId, email, searchText));
};

const getContactList = (companyId: number, companyOwner: number, sortedColumn?: SortedColumn): Promise<ColleagueItem[]> => {
  return getAsync(ApiConstants.company.getContactList(companyId, companyOwner, sortedColumn));
};

const getComapnyById = (companyId: number): Promise<Company> => {
  return getAsync(ApiConstants.company.getCompanyById(companyId));
};

const inviteExpressUser = (contactId: number): Promise<InviteExpressUserResult> => {
  return postAsync(ApiConstants.company.inviteExpressUser(contactId));
};

const addNewUsersExpress = (users: NewUserExpressItem[]): Promise<ColleagueItem[]> => {
  return postAsync(ApiConstants.company.addNewUsersExpress(), users);
};

const getCompanyExpressPreviewInfo = (alias: string, languageId: number): Promise<CompanyExpressPreviewContextType> => {
  return getAsync(ApiConstants.company.getCompanyExpressPreviewInfo(alias, languageId));
};

export const CompanyService = {
  getListCompanyExpress,
  getContactList,
  inviteExpressUser,
  getComapnyById,
  addNewUsersExpress,
  getCompanyExpressPreviewInfo,
};
